import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice";
import products from "./data/products.json";
import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { GoogleOAuthProvider } from "@react-oauth/google";

store.dispatch(setProducts(products));

const container = document.getElementById("root");
const root = createRoot(container);

const clientID =
  "1024259246611-45itr118aipgaijiogp49jbciqr50vh3.apps.googleusercontent.com";

root.render(
  <Provider store={store}>
    <PersistProvider>
      <GoogleOAuthProvider clientId={clientID}>
        <App />
      </GoogleOAuthProvider>
    </PersistProvider>
  </Provider>
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    alert("New version available!  Ready to update?");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
    window.location = "/";
  },
});
