import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

const HomeBookStore = lazy(() => import("./pages/home/HomeBookStore"));

const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

const Product = lazy(() => import("./pages/shop-product/Product"));

const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const AddAddress = lazy(() => import("./pages/other/AddAddress"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));
const Invoice = lazy(() => import("./pages/other/Invoice"));
const PaymentSuccess = lazy(() => import("./pages/other/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./pages/other/PaymentFailed"));

const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const TermsAndConditions = lazy(() =>
  import("./pages/other/TermsAndConditions")
);

const RefundPolicy = lazy(() => import("./pages/other/RefundPolicy"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const Canceled = lazy(() => import("./pages/other/Canceled"));

const queryClient = new QueryClient();

const App = () => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className="flone-preloader-wrapper">
                <div className="flone-preloader">
                  <span></span>
                  <span></span>
                </div>
              </div>
            }
          >
            <Routes>
              <Route
                path={process.env.PUBLIC_URL + "/"}
                element={<HomeBookStore />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/products-list"}
                element={<ShopGridStandard />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/products-list/:categoryID"}
                element={<ShopGridStandard />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/product/:id"}
                element={<Product />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/about"}
                element={<About />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/contact"}
                element={<Contact />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-account"}
                element={<MyAccount />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/login-register"}
                element={<LoginRegister />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/cart"}
                element={<Cart />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/my-orders"}
                element={<MyOrders />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/orders/:orderID/invoice"}
                element={<Invoice />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/wishlist"}
                element={<Wishlist />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/compare"}
                element={<Compare />}
              />
              <Route
                path={process.env.PUBLIC_URL + "/checkout"}
                element={<Checkout />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/checkout/:id"}
                element={<Checkout />}
              />

              <Route
                path={
                  process.env.PUBLIC_URL +
                  "/payment/success/:amount/:bank_ref/:order_id/:payment_type/:status/:tracking_id/"
                }
                element={<PaymentSuccess />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/payment-failed/"}
                element={<PaymentFailed />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/billing-details"}
                element={<AddAddress />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/privacy-policy"}
                element={<PrivacyPolicy />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/terms-and-conditions"}
                element={<TermsAndConditions />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/refund-policy"}
                element={<RefundPolicy />}
              />

              <Route
                path={process.env.PUBLIC_URL + "/canceled"}
                element={<Canceled />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ScrollToTop>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
